<template>
  <div>
    <b-card title="Configuracion SIN - Sincronizacion">
      <b-card-text>
        <b-row>
          <b-col cols="12" md="6">
            <div class="demo-inline-spacing"></div>
          </b-col>
          <b-col cols="12" md="6">
            <b-row style="justify-content: flex-end"> </b-row>
          </b-col>
        </b-row>
      </b-card-text>

      <b-card-text>
        <b-overlay
          :show="loading"
          spinner-variant="dark"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-row>
            <b-col cols="12" md="6">
              <b-card-text>
                <b-card-text>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="dark"
                    class="mr-1"
                    @click.prevent="sincronizarCufd()"
                  >
                    Sincronizar CUFD
                  </b-button>
                </b-card-text>
                <b-form-group
                  label="Vingencia CUFD (Código Único de Facturacion Diaria)"
                  label-for="basicInput"
                >
                  <b-form-input
                    disabled
                    :value="vigenciaCufd"
                    id="basicInput"
                    placeholder=""
                  />
                </b-form-group>
              </b-card-text>
            </b-col>
            <b-col cols="12" md="6">
              <b-card-text>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="dark"
                  class="mr-1"
                  @click.prevent="sincronizarCatalogos()"
                >
                  Sincronizar Catalogo
                </b-button>
              </b-card-text>
            </b-col>
          </b-row>
        </b-overlay>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BTabs,
  BTab,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";
export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      vigenciaCufd: "",
      cufd: {
        codigoPuntoVenta: 0,
        codigoSucursal: 0,
      },
      loading: false,
    };
  },
  created() {},
  methods: {
    async sincronizarCufd() {
      this.loading = true;
      await axios
        .post(`${process.env.VUE_APP_FACT_REST}/facturacion/cufds`, this.cufd, {
          headers: {
            "Content-Type": "application/json",
            apikey: `${process.env.VUE_APP_APIKEY}`,
          },
        })
        .then((res) => {
          this.vigenciaCufd = res.data.fecha;
          this.loading = false;
          this.$bvToast.toast("Sincronizado con exito", {
            title: "EXITO!",
            variant: "primary",
            solid: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$bvToast.toast("No se pudo sincronizar", {
            title: "ADVERTENCIA!",
            variant: "danger",
            solid: false,
          });
        });
    },
    // unidades
    async sincronizarCatalogos() {
      this.loading = true;
      await axios
        .get(
          `${process.env.VUE_APP_FACT_REST}/facturacion/sincronizar/catalogo`,
          {
            headers: {
              "Content-Type": "application/json",
              apikey: `${process.env.VUE_APP_APIKEY}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          this.loading = false;
          this.$bvToast.toast("Sincronizado con exito", {
            title: "EXITO!",
            variant: "primary",
            solid: false,
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$bvToast.toast("No se pudo sincronizar", {
            title: "ADVERTENCIA!",
            variant: "danger",
            solid: false,
          });
          console.log(err);
        });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
</style>
